import { Select, forwardRef } from '@chakra-ui/react';

import type { InputSelectSingleProps } from './type';

import { commonStyle } from '@/components/ui/input/const';

export const InputSelectSingle = forwardRef<InputSelectSingleProps, 'select'>(
  ({ options, placeholder, size = 'md', ...inputProps }, ref) => {
    return (
      <Select
        ref={ref}
        placeholder={
          placeholder === undefined ? '選択する' : placeholder ?? undefined
        }
        {...inputProps}
        {...commonStyle}
        size={size}
        rounded={size === 'sm' ? 'md' : undefined}
      >
        {options.map((o, index) =>
          'options' in o ? (
            <optgroup key={index} label={o.label}>
              {o.options.map((option, i) => (
                <option
                  key={i}
                  value={option.value}
                  disabled={option.disabled || o.disabled}
                  selected={option.selected}
                >
                  {option.label}
                </option>
              ))}
            </optgroup>
          ) : (
            <option
              key={index}
              value={o.value}
              disabled={o.disabled}
              selected={o.selected}
            >
              {o.label}
            </option>
          )
        )}
      </Select>
    );
  }
);

InputSelectSingle.displayName = 'InputSelectSingle';
