import { clsx } from 'clsx';

import type { DefineItemProps, DefinitionListProps } from './type';

export const DefinitionList = ({
  children,
  className,
}: DefinitionListProps) => {
  return (
    <ul
      className={clsx(
        'tw-divide-y tw-divide-solid tw-divide-blackAlpha-200',
        className
      )}
    >
      {children}
    </ul>
  );
};

export const DefineItem = ({
  heading,
  body,
  withPadding,
  ...props
}: DefineItemProps) => {
  return (
    <li
      className={clsx(
        'tw-whitespace-pre-wrap tw-break-all',
        props.labelPosition === 'top' && 'tw-py-3',
        props.labelPosition === 'left' && 'tw-flex tw-py-3.5',
        withPadding && 'tw-px-6'
      )}
    >
      <div
        className={clsx(
          'tw-text-gray-600',
          props.labelPosition === 'top' && 'tw-text-sm tw-mb-1'
        )}
        style={
          props.labelPosition === 'left'
            ? { width: `${props.labelWidth ?? 250}px` }
            : undefined
        }
      >
        {heading}
      </div>
      <div
        className={clsx(props.labelPosition === 'left' && 'tw-flex-1 tw-ml-6')}
      >
        {body}
      </div>
    </li>
  );
};
